<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <div class="item-div">
        <label>昵称：{{communicationInfo.nickname}}</label>
      </div>
      <div class="item-div">
        <label>发布时间：{{communicationInfo.createTime}}</label>
      </div>
      <div class="item-div">
        <label>内容</label>
        <div class="content-div">{{communicationInfo.content}}</div>
      </div>
      <div class="item-div">
        <label>图片({{getImgArray().length}})</label>
        <div class="img-div">
          <img v-for="item in getImgArray()" :src="`${$imgBaseURL}/${item}`" alt="" @click="onShowBigImgDiv($event)">
        </div>
      </div>
      <div class="item-div">
        <label>状态：{{getStateTxt()}}</label>
      </div>
      <div v-show="isRefuse" class="item-div">
        <div>驳回原因</div>
        <label>
          <textarea v-model="rejectMsg" placeholder="请输入驳回原因" />
        </label>
      </div>
      <div v-if="communicationInfo.state !== 3">
        <div class="item-div">审核人：{{communicationInfo.reviewer}}</div>
        <div class="item-div">审核时间：{{communicationInfo.auditTime}}</div>
        <div v-if="communicationInfo.state === 0" class="item-div">驳回原因：{{communicationInfo.rejectMsg}}</div>
      </div>
      <div class="operate-div text-c" v-if="communicationInfo.state === 3">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <input v-show="!isRefuse" type="button" value="通过" @click="onPass">
        <input v-show="isRefuse" type="button" value="确定" @click="onReject">
        <input v-show="!isRefuse" class="refuse-input" type="button" value="驳回" @click="onRefuse">
      </div>
    </div>
  </div>
</template>

<script>
import { getCommunicationDetails, postCommunicationPass, postCommunicationReject } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    communicationId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isRefuse: false,
      communicationInfo: {},
      rejectMsg: "",
      tipMsg: "",
      isShowBigImg: false
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getCommunicationDetails({ id: this.communicationId })
        .then(data => {
          this.communicationInfo = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询动态信息失败");
          }
          this.$layer.close(this.layerid);
        });
    },
    getStateTxt() {
      switch (this.communicationInfo.state) {
        case 0: return "驳回";
        case 1: return "通过";
        case 3: return "待审核";
        default: return "";
      }
    },
    getImgArray() {
      if(Object.prototype.hasOwnProperty.call(this.communicationInfo, "img")){
        return this.communicationInfo.img.length > 0 ? this.communicationInfo.img.split(",") : [];
      }else{
        return [];
      }
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onRefuse() {
      this.isRefuse = true;
    },
    onPass() {
      postCommunicationPass({id: this.communicationId})
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    onReject() {
      if(this.rejectMsg.length < 1){
        this.setTipsMsg("请输入驳回原因");
        return;
      }
      if(this.rejectMsg.length > 50){
        this.setTipsMsg("驳回原因不能超过50个字符，请重新输入");
        return;
      }
      postCommunicationReject({id: this.communicationId, msg: this.rejectMsg})
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 80%;
    margin: 10px auto 0 auto;
    .item-div {
      margin-bottom: 10px;
      .content-div {
        width: calc(100% - 2px);
        height: 100px;
        padding: 3px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #e6e6e6;
      }
      .img-div {
        width: 100%;
        height: 100px;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin-left: 5px;
        }
        img:first-child {
          margin-left: 0;
        }
      }
      textarea {
        width: calc(100% - 8px);
        height: 100px;
        resize: none;
        padding: 3px;
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .refuse-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>